<template>
  <div class="box">
    <header>
      <span class="index"></span>
      <el-button type="text" size="medium" @click="$router.push('/login')">登录</el-button>
    </header>
    <el-carousel>
      <div class="carousel-download">
        <!-- <div>
          <img :src="appdown" alt="">
          <p>扫码下载元慧商APP</p>
        </div> -->
        <div id="carousel-download-center">
          <img :src="weblogo" alt="" class="img1"><img :src="webwenzi" alt="" class="img2">
          <div @click="downloadApp">
            <img :src="webanzhuo" alt="">
            <span>安卓下载</span>
          </div>
        </div>
        <!-- <div>
          <img :src="xcx" alt="">
          <p>扫码前往元慧商小程序</p>
        </div> -->
      </div>
      <el-carousel-item v-for="(item,index) in carsouselImages" :key="index">
        <img class="carousel-img" :src="item.png" alt="">
      </el-carousel-item>
    </el-carousel>
    <div class="app-introduce">
      <div v-for="(item,index) of modules" :key="index">
        <p>{{item.title}}</p>
        <p>{{item.enTitle}}</p>
        <p>{{item.shortDesc}}</p>
        <div class="app-picture">
          <div v-for="(img,ind) of item.images" :key="ind">
            <img :src="img" alt="">
          </div>
        </div>
        <div class="desc-background">
          <div>{{item.desc}}</div>
        </div>
      </div>
    </div>
    <!-- <div class="wrap"> 
      <div>
        <div>
          <img :src="appdown" alt="">
          <p>扫码下载元慧商APP</p>
        </div>
        <div>
          <h2>下载风采印象APP</h2>
          <p>开始您的风采之旅</p>
        </div>
        <div>
          <img :src="xcx" alt="">
          <p>扫码前往元慧商小程序</p>
        </div>
      </div>
    </div> -->
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Footer from '../../components/footer.vue';
export default {
  name: 'Index',
  data() {
    return {
      carsouselImages: window.config.images,
      modules:window.config.actions,
      xcx: window.config.xcx,
      appdown:window.config.appdown,
      weblogo:window.config.weblogo,
      webwenzi:window.config.webwenzi,
      webanzhuo:window.config.webanzhuo,
    }
  },
  components: {
    Footer,
  },
  methods: {
    downloadApp() {
      window.open(baseURL + '/sys/downloadAPK');
    }
  }
}
</script>
<style lang="less" scoped>
  .box{
    height: 100%;
    width: 100%;
  }
  header{
    background-color: #24242401;
    color: #fff;
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    position: fixed;
    top:0;
    z-index: 10;
  }
  .footer{
    width:100%;
    height: 40px;
    position: fixed;
    bottom: 0;
  }
  .el-carousel {
    height: 400px;
    margin-bottom: 20px;
  }
  /deep/ .el-carousel__container {
    height: 400px;
  }
  .carousel-img{
      margin: 0 auto;
      // max-width: 1920px;
      width: 100%;
      height: 400px;
    }
  .desc-background{
    height:200px;
    background: url('/homeImages/beijing1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    margin-top:30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    div {
      font-size:18px;
      color:#fff;
      letter-spacing:2px;
      width:100%;
      margin: 0 auto;
      
    }
  }
  .wrap{
    margin-bottom: 40px;
    overflow-y: auto;
    width: 100%;
    
  }

  .wrap div{
    width: 60%;
    height: 412px;
    margin: 0 auto;
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    img{
      width: 200px;
      height: 200px;
    }
    p{
      margin: 10px;
    }
    div{
      display: flex;
      flex-direction: column;
    }
  }
  
  .index{
    background-color: #de474e;
    display: inline-block;
    text-align: center;
    width: 98px;
  }
  .el-button{
    float: right;
    color: #e4a20c;
    margin-top: 5px;
    font-size: 16px;
    margin-right: 40px;
    cursor: pointer;
  }

  .carousel-download{
    position: absolute;
    left:50%;
    top:50%;
    width: 60%;
    transform:translate(-50%,-50%);
    z-index:9999;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    div{
      text-align: center;
      img{
        width: 200px;
        height: 200px;
      }
    }
  }
  #carousel-download-center{
    padding: 0 30px;
    .img1{
      width:70px;
      height:70px;
      margin-right: 10px;
    }
    .img2{
      height: 70px;
      width: 350px;
    }
    div{
      background: rgb(124, 53, 80);
      height: 70px;
      border-radius: 10px;
      margin-top: 20px;
      text-align: center;
      line-height: 70px;
      cursor: pointer;
      img{
        width: 30px;
        height: 33px;
        margin-right: 10px;
        vertical-align: sub;
      }
      span{
        color: white;
        font-size: 36px;
      }
    }
  }

  .app-introduce {
    div {
      text-align:center;
      // padding-top:40px;
      p:nth-child(1) {
        margin-bottom:10px;
        font-size:32px;
        font-weight:bold;
        letter-spacing:4px;
      }
      p:nth-child(2) {
        margin-bottom:20px;
        font-size:24px;
      }
      p:nth-child(3) {
        margin-bottom:20px;
        font-size:18px;
      }
      .app-picture {
        div {
          display:inline-block;
          margin-left:10px;
          padding:20px 100px;
          background-color:#eb6666;
          img {
            width:270px;
            height:500px;
          }
        }
      }
    }
  }


  @media (max-width:768px) {
    .el-carousel {
      height: 260px;
    }
    .carousel-img{
      margin: 0 auto;
      // max-width: 1920px;
      width: 100%;
      height: 260px;
    }
    /deep/ .el-carousel__container {
      height: 260px;
    }
    .carousel-download{
      position: absolute;
      left:50%;
      top:50%;
      width: 60%;
      transform:translate(-50%,-50%);
      z-index:9999;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      div{
        text-align: center;
        img{
          width: 200px;
          height: 200px;
        }
      }
    }
    #carousel-download-center{
      padding: 0 30px;
      .img1{
        width:40px;
        height:40px;
        margin-right: 10px;
      }
      .img2{
        height: 50px;
        width: 300px;
      }
      div{
        background: rgb(124, 53, 80);
        height: 50px;
        border-radius: 10px;
        margin-top: 20px;
        text-align: center;
        line-height: 50px;
        cursor: pointer;
        img{
          width: 24px;
          height: 27px;
          margin-right: 10px;
          vertical-align: sub;
        }
        span{
          color: white;
          font-size: 26px;
        }
      }
    }
    .app-introduce {
      div {
        text-align:center;
       // padding-top:40px;
        p:nth-child(1) {
          margin-bottom:10px;
          font-size:26px;
          font-weight:bold;
          letter-spacing:4px;
        }
        p:nth-child(2) {
          margin-bottom:10px;
          font-size:20px;
        }
        p:nth-child(3) {
          margin-bottom: 10px;
          font-size: 14px;
        }
        .app-picture {
          div {
            display:inline-block;
            margin-left:10px;
            padding:20px;
            width: 35%;
            background-color:#eb6666;
            img {
              width: 100%;
              height:300px;
            }
          }
        }
      }
    } 
    .desc-background{
      max-height:80px;
      background: url('/homeImages/beijing1.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      margin-top:30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      div {
        font-size:18px;
        color:#fff;
        letter-spacing:2px;
        width:100%;
        margin: 0 auto;
      }
    }  
    .desc-background:last-child {
      margin-bottom: 40px;
    }  
    .footer{
      margin-top: -40px;
      width:100%;
      height: 130px;
      background: #242424;
      position: relative;
    }        
}
</style>
